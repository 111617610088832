<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav
        :pageRow="{ value: pageLengthPer }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          show: true,
          nameDoc: 'Caja negra',
        }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:toggle="eventosCajaNegra($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container class="contenedor-tabla">
        <vue-excel-editor
          ref="grid"
          v-model="jsondata"
          :page="pageLengthPer"
          filter-row
          :localized-label="myLabels"
          remember
          :enterToSouth="enterAbajo"
          free-select
          v-on:toggle-table="activeRigthClick($event)"
          @cell-focus="getLabelColor"
          :cell-style="metodoColorBack"
        >
          <vue-excel-column
            field="_id"
            label="_id"
            type="string"
            :invisible="true"
          />
          <vue-excel-column field="Cliente" label="Cliente" type="string" />
          <vue-excel-column field="Usuario" label="Usuario" type="string" />
          <vue-excel-column
            field="Cambio de:"
            label="Cambio de:"
            type="string"
          />
          <vue-excel-column field="Cambio a:" label="Cambio a:" type="string" />
          <vue-excel-column
            field="Fecha y hora de cambio"
            label="Fecha y hora de cambio"
            type="string"
          />
          <vue-excel-column field="Curso" label="Curso" type="string" />
          <vue-excel-column field="Tipo" label="Tipo" type="string" />
          <vue-excel-column
            field="Fecha de la matrícula"
            label="Fecha de la matrícula"
            type="string"
          />
        </vue-excel-editor>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import ToolBarNav from "./component/ToolBarNav.vue";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],

  components: {
    ToolBarNav,
  },

  data() {
    return {
      pageLengthPer: 20,
      textoActionToolBar: [],
      jsondata: [],
      onSelected: null,
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: false,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
    };
  },

  async created() {
    await this.cargueColors();
  },

  mounted() {
    document.title = "Caja Negra";
    this.initialize();
  },

  methods: {
    initialize() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          axios.post(host + "/historial", fd).then((result) => {
            if (result.data.text == "Error, dirección IP bloqueada") {
              this.$alert("IP bloqueada por el administrador");
              firebase.auth().signOut();
            }
            if (result.data.text == "OK") {
              let data = result.data.historial;
              this.pageLength = data.length;
              for (let i = 0; i < data.length; i++) {
                var fecha = this.$moment(
                  data[i]["Fecha y hora de cambio"]
                ).format("YYYY/MM/DD - HH:mm:ss");
                var fechaM = this.$moment(
                  data[i]["Fecha de la matrícula"]
                ).format("YYYY/MM/DD - HH:mm:ss");
                data[i].fecha = fecha;
                data[i].fechaMatricula = fechaM;
              }
              this.jsondata = data;
              this.cargueCol();
            } else {
              this.$alert("Error al consultar la info de la caja negra");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    // EVENTOS
    eventosCajaNegra(d) {
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },
  },
};
</script>s


<style scoped>
.table {
  height: 80% !important;
}
</style>